document.addEventListener('DOMContentLoaded', event => {
    const html = document.querySelector('html');

    function disableActive() {
        const active = document.querySelectorAll('.tab-control.active');
        const activeTabs = document.querySelectorAll('.tab.active');
        active.forEach(tab => tab.classList.remove('active'));
        activeTabs.forEach(tab => tab.classList.remove('active'));
    }

    function toggle(dropdown) {
        dropdown.classList.toggle('active');
        html.classList.toggle('has-dropdown');
    }

    function navDropdown() {
        const dropdown = document.querySelector('.navigation .dropdown');
        const close = document.querySelector('.mega-menu__close');

        dropdown.addEventListener('click', event => {
            event.preventDefault();
            toggle(dropdown);
        });
        close.addEventListener('click', event => {
            event.preventDefault();
            toggle(dropdown);
        });

        const tabs = document.querySelectorAll('.tabs-controls .tab-control');

        tabs.forEach(tab => {
            tab.addEventListener('click', event => {
                event.preventDefault();
                disableActive();
                event.target.classList.toggle('active');
                const tabContent = document.querySelector(
                    `#${event.target.dataset.id}`
                );
                tabContent.classList.add('active');
            });
        });
    }

    function navToggle() {
        const logo = document.querySelector('.main-header .logo');
        const toggle = document.querySelector('.nav-toggle');
        const menu = document.querySelector('.main-navigation');

        toggle.addEventListener('click', () => {
            logo.classList.toggle('active');
            toggle.classList.toggle('active');
            menu.classList.toggle('active');
            html.classList.toggle('has-nav');
        });
    }

    const tabTitle = document.querySelectorAll('.tab-title');
    function removeActiveTabs() {
        tabTitle.forEach(tab => tab.classList.remove('active'));
    }
    function switchTab() {
        tabTitle.forEach(tab => {
            tab.addEventListener('click', event => {
                event.preventDefault();
                if (!event.target.parentNode.classList.contains('active')) {
                    removeActiveTabs();
                }
                event.target.parentNode.classList.toggle('active');
            });
        });
    }

    navDropdown();
    navToggle();
    switchTab();

    window.addEventListener('scroll', doParallax);
    function doParallax(){
        let positionY = window.pageYOffset/4;
        const symbol = document.querySelector('.background-image');
        symbol.style.top = "-" + positionY + "px";
    }
});
